<template>
  <v-navigation-drawer width="30%" v-model="localDrawer" right temporary app>
    <v-list-item class="primary">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium white--text"
          >Historico de lançamento</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium text-h6">
          Historico de lançamento do <br />
          {{ bimestre + 1 }}° bimestre
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <div v-if="plano.audits.length > 0">
      <v-list class="px-3" v-for="(audit, index) in plano.audits" :key="audit.id" dense>
        <v-list-item-title class="primary rounded-lg pt-4 ps-2">
          <p class="font-weight-light subtitle-1 text-caption">
            Nome:
            <strong>{{ audit.username ? audit.username : "- - -" }}</strong>
          </p>
        </v-list-item-title>
        <v-list-item-title>
          <p class="font-weight-light subtitle-1 text-caption">
            Tipo de operação:
            <strong>{{ audit.event ? events[audit.event] : "- - -" }}</strong>
          </p>
        </v-list-item-title>
        <span v-if="audit.event === 'updated'">
          <v-list-item-title>
            <strong>Anterior</strong>
            <p
              v-for="(a, index) in audit.old_values"
              :key="index"
              class="font-weight-light subtitle-1 text-caption text-wrap"
            >
              <strong>{{ index.split("_").join(" ").toUpperCase() }}</strong
              >: {{ a }}
            </p>
            <v-btn
              v-if="podeRestaurarPlano"
              block
              color="primary"
              class="font-weight-light subtitle-1 text-caption mb-2"
              @click="restaurarPlano(plano.id, audit.id)"
            >
              Restaurar Versão Anterior
            </v-btn>
          </v-list-item-title>
          <v-list-item-title>
            <strong>Depois</strong>
            <p
              v-for="(a, index) in audit.new_values"
              :key="index"
              class="font-weight-light subtitle-1 text-caption text-wrap"
            >
              <strong> {{ index.split("_").join(" ").toUpperCase() }} </strong>: {{ a }}
            </p>
          </v-list-item-title>
          {{ plano.id }} {{ audit.id }}
        </span>
        <v-list-item-title>
          <p class="font-weight-light subtitle-1 text-caption">
            Data da operação:
            <strong>{{ audit.created_at ? dateFormat(audit.created_at) : "- - -" }}</strong>
            <strong> às {{ audit.created_at ? dateFormatHour(audit.created_at) : "- - -" }}</strong>
          </p>
        </v-list-item-title>
        <v-divider v-if="index + 1 != plano.audits.length"></v-divider>
      </v-list>
    </div>
    <v-list-item-title v-else>
      <strong class="subtitle-1 text-caption">Operações não encontradas</strong>
    </v-list-item-title>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { dateFormat, dateFormatHour } from "@/plugins/filters";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    gestao_id: {
      type: Number,
      required: true,
    },
    bimestre: {
      type: Number,
      required: true,
    },
    disciplina_id: {
      type: Number,
      required: true,
      default: null,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    async localDrawer() {
      this.$emit("input", this.localDrawer);
      if (this.localDrawer) {
        await this.historicoPlanosLancados();
      }
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    podeRestaurarPlano() {
      return this.$constants.rolesParaGestores.find(
        (rpg) =>
          rpg.name ===
          this.user.roles
            .filter((role) => role.name === "super-admin" || role.name === "admin")
            .find((r) => true)?.name
      );
    },
  },
  data() {
    return {
      localDrawer: this.value,
      dialog: false,
      plano: {
        audits: [],
      },
      dateFormat,
      dateFormatHour,
      events: {
        created: "Criado",
        updated: "Atualizado",
        deleted: "Deletado",
        restored: "Restaurados",
      },
      masculino: "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png",
      feminino:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbUqkRN4QaEzCW4brgWElc7lv8HdwP8RyxJ89WdDWAoIO-rEUIACXkEXG4n2mDGMLuz4Q&usqp=CAU",
    };
  },
  methods: {
    async historicoPlanosLancados() {
      try {
        const response = await this.$services.gestoesDeAulasService.historicoPlanosLancados(
          this.gestao_id,
          this.bimestre,
          this.disciplina_id
        );

        this.plano = response.historicoLancamento
          ? response.historicoLancamento
          : {
              audits: [],
            };
      } catch (error) {
        this.$handleError(error);
      }
    },
    async restaurarPlano(plano_id, audit_id) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja restaurar este plano?",
        confirmationCode: plano_id,
        confirmationMessage: `Por favor, digite <strong>${plano_id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Restaurando Plano");
              try {
                await this.$services.gestoesDeAulasService.restaurarPlano(plano_id, audit_id);
                this.$toast.success("Plano restaurado com sucesso");
                this.$emit("reloadPage", true);
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              } finally {
                this.$loaderService.close();
              }
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
